import React from "react"
import RacepageFS from "../components/racepageFS"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Dwars door Vlaanderen / A travers les Flandres",
      edition: 2021,
      what: "Startlist",
      updated: "2021-03-23 10:35:52",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21799: {
        teamId: 21799,
        teamUciCode: "AST",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
        teamNationName: "Kazakhstan",
      },
      21800: {
        teamId: 21800,
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
        teamNationName: "Bahrain",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21811: {
        teamId: 21811,
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21821: {
        teamId: 21821,
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
        teamNationName: "Australia",
      },
      21822: {
        teamId: 21822,
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21829: {
        teamId: 21829,
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
        teamNationName: "Netherlands",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21849: {
        teamId: 21849,
        teamUciCode: "BBK",
        teamName: "B&B Hotels p/b KTM",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21852: {
        teamId: 21852,
        teamUciCode: "BWB",
        teamName: "Bingoal-WB",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21863: {
        teamId: 21863,
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21864: {
        teamId: 21864,
        teamUciCode: "ARK",
        teamName: "Team Ark\u00e9a-Samsic",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21867: {
        teamId: 21867,
        teamUciCode: "TDE",
        teamName: "Total Direct Energie",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
    },
    riders: {
      1: {
        id: 16672,
        startno: 1,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      2: {
        id: 28169,
        startno: 2,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      3: {
        id: 8377,
        startno: 3,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-10-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      4: {
        id: 45363,
        startno: 4,
        firstName: "Jasper",
        lastName: "Philipsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-03-02",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      5: {
        id: 20832,
        startno: 5,
        firstName: "Jonas",
        lastName: "Rickaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-07",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      6: {
        id: 6266,
        startno: 6,
        firstName: "Petr",
        lastName: "Vakoc",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1992-07-11",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      7: {
        id: 7964,
        startno: 7,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
      },
      11: {
        id: 12474,
        startno: 11,
        firstName: "Julian",
        lastName: "Alaphilippe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-06-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      12: {
        id: 27121,
        startno: 12,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      13: {
        id: 1970,
        startno: 13,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      14: {
        id: 752,
        startno: 14,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      15: {
        id: 10971,
        startno: 15,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      17: {
        id: 4669,
        startno: 17,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
      },
      21: {
        id: 406,
        startno: 21,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      22: {
        id: 3933,
        startno: 22,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      23: {
        id: 407,
        startno: 23,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      24: {
        id: 390,
        startno: 24,
        firstName: "Marco",
        lastName: "Marcato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1984-02-11",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      25: {
        id: 37777,
        startno: 25,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      26: {
        id: 14325,
        startno: 26,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      27: {
        id: 16998,
        startno: 27,
        firstName: "Ryan",
        lastName: "Gibbons",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1994-08-13",
        teamId: 21838,
        teamName: "UAE Team Emirates",
      },
      31: {
        id: 5059,
        startno: 31,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      32: {
        id: 16793,
        startno: 32,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      33: {
        id: 191,
        startno: 33,
        firstName: "Koen",
        lastName: "De Kort",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-09-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      34: {
        id: 37719,
        startno: 34,
        firstName: "Matteo",
        lastName: "Moschetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-08-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      35: {
        id: 1925,
        startno: 35,
        firstName: "Kiel",
        lastName: "Reijnen",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1986-01-06",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      36: {
        id: 95151,
        startno: 36,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      37: {
        id: 10382,
        startno: 37,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21836,
        teamName: "Trek-Segafredo",
      },
      41: {
        id: 2982,
        startno: 41,
        firstName: "Giacomo",
        lastName: "Nizzolo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-01-30",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      42: {
        id: 19274,
        startno: 42,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      43: {
        id: 2146,
        startno: 43,
        firstName: "Dimitri",
        lastName: "Claeys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-06-18",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      44: {
        id: 6100,
        startno: 44,
        firstName: "Lasse",
        lastName: "Norman Hansen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-11",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      45: {
        id: 45419,
        startno: 45,
        firstName: "Andreas",
        lastName: "Stokbro",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-04-08",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      46: {
        id: 16791,
        startno: 46,
        firstName: "Emil",
        lastName: "Vinjebo",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-24",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      47: {
        id: 9210,
        startno: 47,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-13",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
      },
      51: {
        id: 16786,
        startno: 51,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21832,
        teamName: "Team DSM",
      },
      52: {
        id: 45368,
        startno: 52,
        firstName: "Nils",
        lastName: "Eekhoff",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-01-23",
        teamId: 21832,
        teamName: "Team DSM",
      },
      53: {
        id: 6098,
        startno: 53,
        firstName: "Asbj\u00f8rn Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-04-09",
        teamId: 21832,
        teamName: "Team DSM",
      },
      54: {
        id: 16610,
        startno: 54,
        firstName: "S\u00f8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21832,
        teamName: "Team DSM",
      },
      55: {
        id: 66973,
        startno: 55,
        firstName: "Andreas",
        lastName: "Leknessund",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-05-21",
        teamId: 21832,
        teamName: "Team DSM",
      },
      56: {
        id: 65461,
        startno: 56,
        firstName: "Niklas",
        lastName: "M\u00e4rkl",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-03-03",
        teamId: 21832,
        teamName: "Team DSM",
      },
      57: {
        id: 38146,
        startno: 57,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21832,
        teamName: "Team DSM",
      },
      61: {
        id: 2211,
        startno: 61,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      62: {
        id: 2212,
        startno: 62,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      63: {
        id: 2327,
        startno: 63,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      64: {
        id: 18079,
        startno: 64,
        firstName: "Alex",
        lastName: "Edmondson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-12-22",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      65: {
        id: 44537,
        startno: 65,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      66: {
        id: 16674,
        startno: 66,
        firstName: "Amund Gr\u00f8ndahl",
        lastName: "Jansen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-02-11",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      67: {
        id: 39286,
        startno: 67,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 21821,
        teamName: "Team BikeExchange",
      },
      71: {
        id: 333,
        startno: 71,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      72: {
        id: 37373,
        startno: 72,
        firstName: "Gabriel",
        lastName: "Cullaigh",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-04-08",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      73: {
        id: 65437,
        startno: 73,
        firstName: "Juri",
        lastName: "Hollmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-08-30",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      74: {
        id: 38169,
        startno: 74,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      75: {
        id: 38481,
        startno: 75,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      76: {
        id: 6409,
        startno: 76,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      77: {
        id: 29418,
        startno: 77,
        firstName: "Sebastian",
        lastName: "Mora",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1988-02-19",
        teamId: 21822,
        teamName: "Movistar Team",
      },
      81: {
        id: 6228,
        startno: 81,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      82: {
        id: 9042,
        startno: 82,
        firstName: "Jasper",
        lastName: "De Buyst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-11-24",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      83: {
        id: 840,
        startno: 83,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      84: {
        id: 44622,
        startno: 84,
        firstName: "Harry",
        lastName: "Sweeny",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-07-09",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      85: {
        id: 1717,
        startno: 85,
        firstName: "Tosh",
        lastName: "Van der Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-11-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      86: {
        id: 53489,
        startno: 86,
        firstName: "Brent",
        lastName: "Van Moer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-12",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      87: {
        id: 69912,
        startno: 87,
        firstName: "Florian",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-03-12",
        teamId: 21819,
        teamName: "Lotto Soudal",
      },
      91: {
        id: 27097,
        startno: 91,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      92: {
        id: 45433,
        startno: 92,
        firstName: "David",
        lastName: "Dekker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-02",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      93: {
        id: 37308,
        startno: 93,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      94: {
        id: 97547,
        startno: 94,
        firstName: "Olav",
        lastName: "Kooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-10-17",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      95: {
        id: 2166,
        startno: 95,
        firstName: "Christoph",
        lastName: "Pfingsten",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1987-11-20",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      96: {
        id: 729,
        startno: 96,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      97: {
        id: 367,
        startno: 97,
        firstName: "Maarten",
        lastName: "Wynants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-05-13",
        teamId: 21829,
        teamName: "Jumbo-Visma",
      },
      101: {
        id: 1581,
        startno: 101,
        firstName: "Sep",
        lastName: "Vanmarcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-07-28",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      102: {
        id: 377,
        startno: 102,
        firstName: "Andr\u00e9",
        lastName: "Greipel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1982-07-16",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      103: {
        id: 32892,
        startno: 103,
        firstName: "Hugo",
        lastName: "Hofstetter",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-02-13",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      104: {
        id: 70759,
        startno: 104,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-01",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      105: {
        id: 16615,
        startno: 105,
        firstName: "Mads",
        lastName: "W\u00fcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      106: {
        id: 7579,
        startno: 106,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-04-19",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      107: {
        id: 7731,
        startno: 107,
        firstName: "Rick",
        lastName: "Zabel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-12-07",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
      },
      111: {
        id: 2961,
        startno: 111,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      112: {
        id: 20581,
        startno: 112,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      113: {
        id: 15686,
        startno: 113,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      114: {
        id: 41249,
        startno: 114,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-03-04",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      115: {
        id: 65025,
        startno: 115,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      116: {
        id: 2313,
        startno: 116,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      117: {
        id: 7724,
        startno: 117,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
      },
      121: {
        id: 16581,
        startno: 121,
        firstName: "Aim\u00e9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      122: {
        id: 18249,
        startno: 122,
        firstName: "Tom",
        lastName: "Devriendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-29",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      123: {
        id: 9349,
        startno: 123,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      124: {
        id: 3147,
        startno: 124,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      125: {
        id: 3187,
        startno: 125,
        firstName: "Boy",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1988-01-18",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      126: {
        id: 7730,
        startno: 126,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      127: {
        id: 1267,
        startno: 127,
        firstName: "Pieter",
        lastName: "Vanspeybrouck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-02-10",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
      },
      131: {
        id: 2337,
        startno: 131,
        firstName: "Arnaud",
        lastName: "D\u00e9mare",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-08-26",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      132: {
        id: 37325,
        startno: 132,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      133: {
        id: 16724,
        startno: 133,
        firstName: "Stefan",
        lastName: "K\u00fcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      134: {
        id: 10871,
        startno: 134,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      135: {
        id: 12493,
        startno: 135,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      136: {
        id: 4317,
        startno: 136,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      137: {
        id: 37379,
        startno: 137,
        firstName: "Valentin",
        lastName: "Madouas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
      },
      141: {
        id: 20584,
        startno: 141,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      142: {
        id: 46157,
        startno: 142,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      143: {
        id: 6309,
        startno: 143,
        firstName: "Michael",
        lastName: "Valgren",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-07",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      144: {
        id: 920,
        startno: 144,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      145: {
        id: 312,
        startno: 145,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      146: {
        id: 46161,
        startno: 146,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      147: {
        id: 38164,
        startno: 147,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
      },
      151: {
        id: 1310,
        startno: 151,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21807,
        teamName: "Cofidis",
      },
      152: {
        id: 27331,
        startno: 152,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 21807,
        teamName: "Cofidis",
      },
      153: {
        id: 16690,
        startno: 153,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-17",
        teamId: 21807,
        teamName: "Cofidis",
      },
      154: {
        id: 1982,
        startno: 154,
        firstName: "Jempy",
        lastName: "Drucker",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1986-09-03",
        teamId: 21807,
        teamName: "Cofidis",
      },
      155: {
        id: 14737,
        startno: 155,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21807,
        teamName: "Cofidis",
      },
      156: {
        id: 4927,
        startno: 156,
        firstName: "Kenneth",
        lastName: "Vanbilsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-06-01",
        teamId: 21807,
        teamName: "Cofidis",
      },
      157: {
        id: 1276,
        startno: 157,
        firstName: "Jelle",
        lastName: "Wallays",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-11",
        teamId: 21807,
        teamName: "Cofidis",
      },
      161: {
        id: 18260,
        startno: 161,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      162: {
        id: 9746,
        startno: 162,
        firstName: "Pascal",
        lastName: "Ackermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-17",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      163: {
        id: 37439,
        startno: 163,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1997-02-18",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      164: {
        id: 45364,
        startno: 164,
        firstName: "Jordi",
        lastName: "Meeus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-01",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      165: {
        id: 8388,
        startno: 165,
        firstName: "Lukas",
        lastName: "P\u00f6stlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1992-01-10",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      166: {
        id: 3226,
        startno: 166,
        firstName: "Michael",
        lastName: "Schwarzmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-01-07",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      167: {
        id: 45352,
        startno: 167,
        firstName: "Matthew",
        lastName: "Walls",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-04-20",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
      },
      171: {
        id: 8068,
        startno: 171,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      172: {
        id: 16816,
        startno: 172,
        firstName: "Phil",
        lastName: "Bauhaus",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-07-08",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      173: {
        id: 9014,
        startno: 173,
        firstName: "Chun Kai",
        lastName: "Feng",
        nationCode: "TPE",
        nationName: "Taiwan",
        birthDate: "1988-11-02",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      174: {
        id: 6246,
        startno: 174,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-01-04",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      175: {
        id: 120,
        startno: 175,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      176: {
        id: 80489,
        startno: 176,
        firstName: "Jonathan",
        lastName: "Milan",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      177: {
        id: 376,
        startno: 177,
        firstName: "Marcel",
        lastName: "Sieberg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1982-04-30",
        teamId: 21800,
        teamName: "Bahrain Victorious",
      },
      181: {
        id: 6230,
        startno: 181,
        firstName: "Alexey",
        lastName: "Lutsenko",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1992-09-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      182: {
        id: 75603,
        startno: 182,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      183: {
        id: 27257,
        startno: 183,
        firstName: "Yevgeniy",
        lastName: "Gidich",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-05-19",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      184: {
        id: 5836,
        startno: 184,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      185: {
        id: 3199,
        startno: 185,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      186: {
        id: 14717,
        startno: 186,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      187: {
        id: 16594,
        startno: 187,
        firstName: "Ben",
        lastName: "Perry",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1994-03-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
      },
      191: {
        id: 37427,
        startno: 191,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      192: {
        id: 14742,
        startno: 192,
        firstName: "Julien",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-27",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      193: {
        id: 12800,
        startno: 193,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-03-05",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      194: {
        id: 31612,
        startno: 194,
        firstName: "Lawrence",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-08-28",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      195: {
        id: 17210,
        startno: 195,
        firstName: "Marc",
        lastName: "Sarreau",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-10",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      196: {
        id: 327,
        startno: 196,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      197: {
        id: 27304,
        startno: 197,
        firstName: "Damien",
        lastName: "Touz\u00e9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-07",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
      },
      201: {
        id: 6104,
        startno: 201,
        firstName: "Warren",
        lastName: "Barguil",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-10-28",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      202: {
        id: 13898,
        startno: 202,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-11-29",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      203: {
        id: 19254,
        startno: 203,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      204: {
        id: 20104,
        startno: 204,
        firstName: "Benjamin",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-04",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      205: {
        id: 49662,
        startno: 205,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      206: {
        id: 37495,
        startno: 206,
        firstName: "Bram",
        lastName: "Welten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-29",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      207: {
        id: 16154,
        startno: 207,
        firstName: "Daniel",
        lastName: "McLay",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-01-03",
        teamId: 21864,
        teamName: "Team Ark\u00e9a-Samsic",
      },
      211: {
        id: 29,
        startno: 211,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      212: {
        id: 31,
        startno: 212,
        firstName: "Edvald",
        lastName: "Boasson Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      213: {
        id: 330,
        startno: 213,
        firstName: "Damien",
        lastName: "Gaudin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-08-20",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      214: {
        id: 16783,
        startno: 214,
        firstName: "Christopher",
        lastName: "Lawless",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-11-04",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      215: {
        id: 3045,
        startno: 215,
        firstName: "Adrien",
        lastName: "Petit",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-09-26",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      216: {
        id: 2326,
        startno: 216,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      217: {
        id: 16637,
        startno: 217,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21867,
        teamName: "Total Direct Energie",
      },
      221: {
        id: 16752,
        startno: 221,
        firstName: "Bryan",
        lastName: "Coquard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-04-25",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      222: {
        id: 32941,
        startno: 222,
        firstName: "Nicola",
        lastName: "Bagioli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-02-19",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      223: {
        id: 46501,
        startno: 223,
        firstName: "Cyril",
        lastName: "Barthe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-02-14",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      224: {
        id: 1966,
        startno: 224,
        firstName: "Jens",
        lastName: "Debusschere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-08-28",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      225: {
        id: 32609,
        startno: 225,
        firstName: "J\u00e9r\u00e9my",
        lastName: "Lecroq",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-04-07",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      226: {
        id: 170,
        startno: 226,
        firstName: "Cyril",
        lastName: "Lemoine",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1983-03-03",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      227: {
        id: 6402,
        startno: 227,
        firstName: "Eliot",
        lastName: "Lietaer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-08-15",
        teamId: 21849,
        teamName: "B&B Hotels p/b KTM",
      },
      231: {
        id: 742,
        startno: 231,
        firstName: "Jelle",
        lastName: "Vanendert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-02-19",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      232: {
        id: 6519,
        startno: 232,
        firstName: "Sean",
        lastName: "De Bie",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-03",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      233: {
        id: 20822,
        startno: 233,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      234: {
        id: 37996,
        startno: 234,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-10-31",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      235: {
        id: 71209,
        startno: 235,
        firstName: "Tom",
        lastName: "Paquot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-22",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      236: {
        id: 18574,
        startno: 236,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-05-22",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      237: {
        id: 9072,
        startno: 237,
        firstName: "Boris",
        lastName: "Vall\u00e9e",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-03",
        teamId: 21852,
        teamName: "Bingoal-WB",
      },
      241: {
        id: 45354,
        startno: 241,
        firstName: "Ruben",
        lastName: "Apers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-25",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      242: {
        id: 45371,
        startno: 242,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-05-25",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      243: {
        id: 10952,
        startno: 243,
        firstName: "Thomas",
        lastName: "Sprengers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-02-05",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      244: {
        id: 45356,
        startno: 244,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      245: {
        id: 14780,
        startno: 245,
        firstName: "Kenneth",
        lastName: "Van Rooy",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-08",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      246: {
        id: 37434,
        startno: 246,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-04",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
      247: {
        id: 64654,
        startno: 247,
        firstName: "Ward",
        lastName: "Vanhoof",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-18",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
      },
    },
  }
  const race = "Dwars door Vlaanderen"
  const raceID = 5

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
